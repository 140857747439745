@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --color-paper: #f4f1e4;
  --color-ink: #1a1a1a;
  --color-accent: #8b0000;
  --font-serif: 'Playfair Display', serif;
  --font-sans: 'Source Sans Pro', sans-serif;
  --font-mono: 'Courier Prime', monospace;
}

body {
  background-color: var(--color-paper);
  color: var(--color-ink);
  font-family: var(--font-sans);
}

.newspaper-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.newspaper-header {
  font-family: var(--font-serif);
  font-weight: bold;
  text-transform: uppercase;
}

.newspaper-subheader {
  font-family: var(--font-serif);
  font-style: italic;
}

.newspaper-body {
  font-family: var(--font-sans);
  line-height: 1.6;
}

.newspaper-border {
  border: 1px solid var(--color-ink);
  padding: 1rem;
  position: relative;
}

.newspaper-border::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 1px solid var(--color-ink);
  pointer-events: none;
}

.rocket-trail {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #0d9488;
    opacity: 0.7;
    pointer-events: none;
  }

  .claude-stamp {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: #0d9488;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: rotate(-5deg);
    transition: all 0.3s ease;
  }

  .claude-stamp:hover {
    transform: rotate(-5deg) scale(1.05);
    background-color: #0f766e;
  }

  .nav-item {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #0d9488;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }
    &:hover::after {
      transform: scaleX(1);
    }
  }


/* Add more custom classes as needed */